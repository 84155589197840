import axios from 'axios'
import * as amplitude from '@amplitude/analytics-browser'

import { returnErrors } from './messages'
import {
  GET_CUSTOMER,
  CREATE_CHECKOUT_SESSION,
  DELETE_CHECKOUT_SESSION,
  CUSTOMER_LOADING,
  CREATE_CHECKOUT_SESSION_FAIL
} from './types'
import { tokenConfig } from './auth'

const PROXY = process.env.REACT_APP_PROXY

// Get customer
export const getCustomer = () => (dispatch, getState) => {
  dispatch({ type: CUSTOMER_LOADING })
  dispatch({ type: DELETE_CHECKOUT_SESSION })
  axios
    .get(`${PROXY}/v1/customer/`, tokenConfig(getState))
    .then(res => {
      const responseData = res.data
      dispatch({
        type: GET_CUSTOMER,
        payload: responseData
      })

      // Use our customer Id to login to amplitude
      if (responseData && responseData[0] && responseData[0].customer_id) {
        const customerId = responseData[0].customer_id
        if (customerId) amplitude.setUserId(customerId)
      }
    })
    .catch(err => {
      const error =
        err.response && err.response.data ? err.response.data : 'Please try again later.'
      const status = err.response && err.response.status ? err.response.status : 500
      // dispatch(returnErrors(error, status))
      dispatch({ type: CREATE_CHECKOUT_SESSION_FAIL })
    })
}

// Create checkout session for user.
export const createCheckoutSession = priceId => (dispatch, getState) => {
  dispatch({ type: DELETE_CHECKOUT_SESSION })
  axios
    .post(`${PROXY}/v1/create_checkout_session/`, { price_id: priceId }, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CREATE_CHECKOUT_SESSION,
        payload: res.data
      })
    })
    .catch(err => {
      const error =
        err.response && err.response.data ? err.response.data : 'Please try again later.'
      const status = err.response && err.response.status ? err.response.status : 500
      dispatch(returnErrors(error, status))
      dispatch({
        type: CREATE_CHECKOUT_SESSION_FAIL
      })
    })
}

// Navigate to the customer portal
export const createCustomerPortal = () => (dispatch, getState) => {
  dispatch({ type: DELETE_CHECKOUT_SESSION })
  axios
    .post(`${PROXY}/v1/customer_portal/`, {}, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CREATE_CHECKOUT_SESSION,
        payload: res.data
      })
    })
    .catch(err => {
      const error =
        err.response && err.response.data ? err.response.data : 'Please try again later.'
      const status = err.response && err.response.status ? err.response.status : 500
      dispatch(returnErrors(error, status))
      dispatch({
        type: CREATE_CHECKOUT_SESSION_FAIL
      })
    })
}
